<template>
    <div class="test">
        <div class="page_1">
            <PageNav :show_num="4" />
            <div class="page_1_center_box">
                <div class="cen-text">
                    ZT8213 Full-network <br>Industry Terminal
                </div>
                <div class="sub-text">
                    IP67, 1.2m drop prevention.
                </div>
            </div>
        </div>
        <div class="part-2">
            <h3>5.7 inch HD screen, 12.6mm ultra-thin body, 2.5D Corning III glass cover, IP67 high protection level. High-speed quad-core/octa-core processor. Support fingerprint and fast charging.</h3>
        </div>
        <div class="part-3 part-3-1">
            <div class="part-3-main">5.7 " HD display</div>
            <div class="part-3-sub">
                Multi-touch capacitive screen, 2.5D Corning III glass cover.
            </div>
        </div>
        <div class="part-3 part-3-2">
            <div class="part-3-main">Fast Scan and Upload</div>
            <div class="part-3-sub">WiFi, 2G/3G/4G, Bluetooth</div>
            <div class="tip-1">1D</div>
            <div class="tip-2">2D</div>
            <div class="tip-3">NFC</div>
        </div>
        <div class="part-7">
            <h1>Application scenarios</h1>
            <div class="part-7-show">
                <div class="part-7-item">
                    <img src="../../assets/images/prods/zt8213/as1.jpg" />
                    <label>Convenient Store</label>
                </div>
                <div class="part-7-item">
                    <img src="../../assets/images/prods/zt8213/as2.jpg" />
                    <label>Warehouse</label>
                </div>
                <div class="part-7-item">
                    <img src="../../assets/images/prods/zt8213/as3.jpg" />
                    <label>Restaurant</label>
                </div>
            </div>
        </div>
        <ProdSpec :data="zt8213.data" />

        <PageBot :show_num="4" />
        <el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
            <svg class="icon icon_xiangshang" aria-hidden="true">
                <use xlink:href="#icon-xiangshang"></use>
            </svg>
        </el-backtop>
    </div>
</template>

<script>
import { zt8213 } from '@/views/products/spec/spec_en';
import ProdSpec from "@/components/ProdSpec.vue";
import PageNav from "@/components/PageNav.vue"
import PageBot from "@/components/PageBottom.vue"
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import {
    resetRem
} from '@/all_js/all';
import {
    clickEffect
} from "@/all_js/click";
export default {
    name: 'ZT8213',
    components: {
        PageBot,
        PageNav,
        ProdSpec
    },
    created() {
    },
    watch: {

    },

    mounted() {

    },
    data() {
        return {
            avtive_nav: "",
            screenWidth: null,
            list_status: true,
            avtive_img: 0,
            zt8213
        }
    },
    methods: {
    }
}
</script>
<style scoped lang="less">
* {
    margin: 0;
    padding: 0;
    // transition: all 1s; // 设置动画效果
}

li {
    list-style-type: none;
}

.page_1 {
    width: 100%;
    height: 100vh;
    max-height: 950px;
    object-fit: cover; // 保持图片缩放时候不变形
    background-image: url("../../assets/images/prods/zt8213/bg1.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; // 子元素无法被选中

    .page_1_center_box {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #fff;

        .cen-text {
            margin-top: 30vh;
            margin-left: 15%;
            // background-color: aqua;
            font-size: 60px;
            font-weight: 600;
        }

        .sub-text {
            margin-left: 15%;
            margin-top: 1vh;
            font-size: 28px;
        }
    }
}

.part-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: #B1BDC9;

    h3 {
        width: 60%;
        color: #fff;
        font-weight: 400;
        font-size: 20px;
        text-overflow: ellipsis;
        text-align: center;
        line-height: 1.5;
    }
}

.part-3 {
    height: 90vh;
    background-size: 100% 100%;
    position: relative;

    div {
        color: #fff;
    }

    .part-3-main {
        font-size: 55px;
        position: absolute;
        left: 51%;
        top: 30%;
    }

    .part-3-sub {
        font-size: 24px;
        position: absolute;
        left: 51%;
        top: 43%;
    }
}

.part-3-1 {
    background-image: url("../../assets/images/prods/zt8213/bg2.jpg");
}

.part-3-2 {
    background-image: url("../../assets/images/prods/zt8213/bg3.jpg");

    .part-3-main {
        top: 8%;
        left: 50%;
        transform: translateX(-50%);
    }

    .part-3-sub {
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
    }

    .tip-1 {
        position: absolute;
        left: 18.5%;
        top: 50%;
    }

    .tip-2 {
        position: absolute;
        left: 23.5%;
        top: 50%;
    }

    .tip-3 {
        position: absolute;
        left: 28%;
        top: 50%;
    }
}

.part-7 {
    background-color: #FAFAFA;
    padding: 72px;

    h1 {
        text-align: center;
        font-size: 55px;
    }

    .part-7-show {
        display: flex;
        justify-content: center;
        margin-top: 45px;

        .part-7-item {
            margin: 0 10px;

            display: flex;
            flex-direction: column;
            align-items: center;

            label {
                font-size: 28px;
                color: #0080CC;
                margin-top: 20px;
            }
        }
    }
}

.back_up {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: #49b1f5;

    .icon_xiangshang {
        height: 30px;
        width: 30px;
        border-radius: 30px;
    }
}

.back_up:hover {
    background-color: #49b1f5;
}
</style>
<style scoped lang="less">
// @media only screen and (min-width:1371px) {}
</style>